import { createApi } from '@reduxjs/toolkit/query/react';
import {
  LeaderboardResponse,
  LeaderboardRawResponse,
  ILeaderboardPayload,
  IFan,
  IBadgeLeaderboardPayload,
  ICustomLeaderboardResponse,
  ICustomLeaderboardParams,
  ICreateCustomLeaderboardPayload,
  IUpdateCustomLeaderboardPayload,
  IFanLeaderboardParams,
  ILeaderboardFanRawResponse,
} from '@ui/components';
import { QUERY } from '@constants/query.constants';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { cookieService } from '@lib/cookie.service';
import dayjs from 'dayjs';
import { DATE_FORMATS } from '@ui/constants';
import { authQuery } from '../config/query.config';

export const fansAdapter = createEntityAdapter({
  selectId: (item: IFan) => item.id,
});

export const fansSelector = fansAdapter.getSelectors();

export const leaderboardApi = createApi({
  reducerPath: 'leaderboard-api',
  baseQuery: authQuery,
  tagTypes: [
    QUERY.globalLeaderboard,
    QUERY.leaderboards,
    QUERY.singleLeaderboard,
    QUERY.leaderboardFans,
  ],
  endpoints: (builder) => ({
    getCustomLeaderboards: builder.query<ICustomLeaderboardResponse, ICustomLeaderboardParams>({
      query: ({ initial_date = '', final_date = '', page = 1, pageSize = 10 }) => {
        const userId = cookieService.getUserId();
        return {
          url: `public/v1/list_leaderboards/${userId}`,
          params: {
            initial_date,
            final_date,
            creator_id: userId,
            page,
            items_per_page: pageSize,
          },
        };
      },
      transformResponse: ({ leaderboards, total_pages }: ICustomLeaderboardResponse) => {
        return {
          leaderboards,
          total_pages,
        };
      },
      providesTags: [QUERY.leaderboards],
    }),
    getLeaderboardFans: builder.query<LeaderboardResponse, IFanLeaderboardParams>({
      query: ({ page = 1, pageSize = 10, leaderboardId, top_n }) => {
        const user_id = cookieService.getUserId();

        return {
          url: `public/v1/display_leaderboard_fans/${leaderboardId}`,
          params: {
            page,
            leaderboard_id: leaderboardId,
            items_per_page: pageSize,
            top_n,
            user_id,
          },
        };
      },
      transformResponse({
        fans,
        total_pages,
        total_fans,
      }: ILeaderboardFanRawResponse): LeaderboardResponse {
        const fanEntities = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          fans.map((fan) => ({
            name: fan.username,
            score: fan.score,
            id: fan.reference_id,
            rank: fan.rank,
            avatar: fan.avatar_url,
            rankOutOf: total_fans,
          })),
        );

        return {
          fans: { ...fanEntities },
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameLeaderboard = currentArg.leaderboardId === previousArg?.leaderboardId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameLeaderboard || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.leaderboardId}`;
      },
      merge(currentState, incomingState) {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
      providesTags: [QUERY.leaderboardFans],
    }),
    getGlobalLeaderboard: builder.query<LeaderboardResponse, ILeaderboardPayload>({
      query: ({
        creatorId,
        initialDate,
        finalDate,
        weighted,
        page,
        pageSize,
        scoreRanking = 'badges',
      }) => {
        return {
          url: `public/v1/top_fans_by_creator/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}&top_n=${0}&weighted=${weighted}&page=${page}&items_per_page=${pageSize}&score_ranking=${scoreRanking}`,
        };
      },
      transformResponse({ data, total_pages }: LeaderboardRawResponse) {
        const fans = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          data
            ? data.map((fan) => {
                return {
                  name: fan.username,
                  id: fan.userId,
                  score: fan.score,
                  engagement_score: fan.engagement_score,
                  overall_score: fan.overall_score,
                  rank: fan.rank,
                  avatar: fan.avatarUrl,
                };
              })
            : [],
        );

        return {
          fans: fans,
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameCreator = currentArg.creatorId === previousArg?.creatorId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameCreator || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.creatorId}`;
      },
      merge(currentState, incomingState) {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
      providesTags: [QUERY.globalLeaderboard],
    }),
    getBadgeLeaderboard: builder.query<LeaderboardResponse, IBadgeLeaderboardPayload>({
      query: ({ badgeId, initialDate, finalDate, weighted, page, pageSize }) => {
        return {
          url: `public/v1/top_fans_by_badge/${badgeId}?initial_date=${initialDate}&final_date=${finalDate}&top_n=${0}&weighted=${weighted}&page=${page}&items_per_page=${pageSize}`,
        };
      },
      transformResponse({ data, total_pages }: LeaderboardRawResponse) {
        const fans = fansAdapter.addMany(
          fansAdapter.getInitialState(),
          data
            ? data.map((fan) => {
                return {
                  name: fan.username,
                  id: fan.userId,
                  score: fan.score,
                  rank: fan.rank,
                  avatar: fan.avatarUrl,
                };
              })
            : [],
        );

        return {
          fans: fans,
          totalPages: total_pages,
        };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const isSameBadge = currentArg.badgeId === previousArg?.badgeId;
        const isSamePage = currentArg.page === previousArg?.page;
        return !isSameBadge || !isSamePage;
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.badgeId}`;
      },
      merge: (currentState, incomingState) => {
        fansAdapter.addMany(currentState.fans, fansSelector.selectAll(incomingState.fans));
      },
    }),
    removeCustomLeaderboard: builder.mutation<void, string>({
      query(leaderboardId) {
        return {
          url: `public/v1/remove_leaderboard/${leaderboardId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [QUERY.leaderboards],
    }),
    createCustomLeaderboard: builder.mutation<void, ICreateCustomLeaderboardPayload>({
      query({ by_badges, initial_date, final_date, fans_quantity, name, description }) {
        const user_id = cookieService.getUserId();
        return {
          url: `public/v1/top_fans_by_badge/${user_id}`,
          method: 'GET',
          params: {
            user_id,
            initial_date: dayjs(initial_date).format(DATE_FORMATS.api),
            final_date: dayjs(final_date).format(DATE_FORMATS.api),
            top_n: fans_quantity,
            custom_leaderboard_name: name,
            badges: by_badges,
            description,
          },
        };
      },
      invalidatesTags: [QUERY.leaderboards],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          // It seems the backend may be processing the mutation in a queue, so we need to wait for it
          setTimeout(() => {
            dispatch(leaderboardApi.util.invalidateTags([QUERY.leaderboards]));
          }, 50);
        } catch (error) {
          return error;
        }
      },
    }),
    updateCustomLeaderboard: builder.mutation<void, IUpdateCustomLeaderboardPayload>({
      query({ id, name, description, by_badges, fans_quantity, final_date, initial_date }) {
        const user_id = cookieService.getUserId();
        return {
          url: `public/v1/top_fans_by_badge/${user_id}`,
          method: 'GET',
          params: {
            id,
            user_id,
            initial_date,
            final_date,
            top_n: fans_quantity,
            custom_leaderboard_name: name,
            badges: by_badges,
            description,
          },
        };
      },
      invalidatesTags: [QUERY.leaderboards],
    }),
    getLeaderboardById: builder.query<ICustomLeaderboardResponse, string>({
      query(id) {
        return {
          url: `public/v1/leaderboard/${id}`,
        };
      },
    }),
  }),
});

export const {
  useGetCustomLeaderboardsQuery,
  useGetGlobalLeaderboardQuery,
  useGetBadgeLeaderboardQuery,
  useGetLeaderboardFansQuery,
  useLazyGetLeaderboardFansQuery,
  useCreateCustomLeaderboardMutation,
  useUpdateCustomLeaderboardMutation,
  useRemoveCustomLeaderboardMutation,
  useGetLeaderboardByIdQuery,
} = leaderboardApi;
