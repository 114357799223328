import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { authApi } from './api/auth';
import { userApi } from './api/user-api';
import { messagesApi } from './api/messages-api';
import { badgesApi } from './api/badges-api';
import { leaderboardApi } from './api/leaderboard-api';
import { metricsApi } from './api/metrics';
import { badgePreviewApi } from './api/badge-preview-api';
import { aiApi } from './api/ai-api';
import { appReducer } from './features/app';
import { toastReducer } from './features/toast';
import { groupsApi } from './api/groups-api';
import { conversationsApi } from './api/conversations-api';
import { messagesApiV2 } from './api/messages-api-v2';
import { autocompleteApi } from './api/autocomplete-api';
import { notificationApi } from './api/notification-api';
import { engagementApi } from './api/engagement-api';
import { challengeApi } from './api/challenge-api';

export const store = configureStore({
  reducer: {
    app: appReducer,
    toast: toastReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [messagesApi.reducerPath]: messagesApi.reducer,
    [badgesApi.reducerPath]: badgesApi.reducer,
    [leaderboardApi.reducerPath]: leaderboardApi.reducer,
    [metricsApi.reducerPath]: metricsApi.reducer,
    [badgePreviewApi.reducerPath]: badgePreviewApi.reducer,
    [aiApi.reducerPath]: aiApi.reducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [conversationsApi.reducerPath]: conversationsApi.reducer,
    [messagesApiV2.reducerPath]: messagesApiV2.reducer,
    [autocompleteApi.reducerPath]: autocompleteApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [engagementApi.reducerPath]: engagementApi.reducer,
    [challengeApi.reducerPath]: challengeApi.reducer,
  },
  middleware: (getDefultMiddleware) =>
    getDefultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      messagesApi.middleware,
      badgesApi.middleware,
      leaderboardApi.middleware,
      metricsApi.middleware,
      badgePreviewApi.middleware,
      aiApi.middleware,
      groupsApi.middleware,
      conversationsApi.middleware,
      messagesApiV2.middleware,
      autocompleteApi.middleware,
      notificationApi.middleware,
      engagementApi.middleware,
      challengeApi.middleware,
    ]),
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
