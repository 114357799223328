import { createApi } from '@reduxjs/toolkit/query/react';
import { dataQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import {
  ITotalFansResponse,
  ITotalFansPayload,
  ITotalClaimedBadgesResponse,
  ITotalClaimedBadgesPayload,
  IHistoricClaimResponse,
  IHistoricClaimPayload,
  ITotalCollabPayload,
  ITotalCollabResponse,
  IHistoricClaimsPayload,
  IHistoricClaimsResponse,
} from './types';

export const metricsApi = createApi({
  reducerPath: 'metricsApi',
  baseQuery: dataQuery,
  endpoints: (builder) => ({
    getFans: builder.query<ITotalFansResponse, ITotalFansPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/total_fans_by_creator/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}`,
        };
      },
    }),
    getNewFans: builder.query<ITotalFansResponse, ITotalFansPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/total_new_fans_by_creator/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}`,
        };
      },
    }),
    getTotalBadgeClaims: builder.query<ITotalClaimedBadgesResponse, ITotalClaimedBadgesPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/total_collectibles_by_source/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}`,
        };
      },
    }),
    getHistoricBadgeClaims: builder.query<IHistoricClaimResponse, IHistoricClaimPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/historic_claims_by_creator/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}&granularity=DAILY`,
        };
      },
    }),
    getHistoricCollabClaims: builder.query<IHistoricClaimResponse, IHistoricClaimPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/historic_collaborated_claims_by_creator/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}&granularity=DAILY`,
        };
      },
    }),
    getTotalCollaborationBadges: builder.query<ITotalCollabResponse, ITotalCollabPayload>({
      query: ({ initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/collaborated_collectibles_by_user/${creatorId}?initial_date=${initialDate}&final_date=${finalDate}`,
        };
      },
    }),
    // Badge historic
    getHistoricClaimedBadge: builder.query<IHistoricClaimsResponse, IHistoricClaimsPayload>({
      query: ({ badgeId, initialDate, finalDate }) => {
        const creatorId = cookieService.getUserId();
        return {
          url: `/public/v1/historic_claims_by_badge/${badgeId}?initial_date=${initialDate}&final_date=${finalDate}&granularity=DAILY`,
          method: 'POST',
          body: {
            badges: [badgeId],
          },
        };
      },
    }),
  }),
});

export const {
  useGetFansQuery,
  useGetNewFansQuery,
  useGetTotalBadgeClaimsQuery,
  useGetHistoricBadgeClaimsQuery,
  useGetHistoricCollabClaimsQuery,
  useGetTotalCollaborationBadgesQuery,
  useGetHistoricClaimedBadgeQuery,
} = metricsApi;
